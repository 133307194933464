@import "../../styles/theme.scss";

.WorkplaceCultureOverview {
  padding: 3rem;
  text-align: center;
  position: relative;
  margin-bottom: 1rem;

  &__title {
    margin-bottom: 2.5rem;

    &__actions {
      display: flex;
      justify-content: flex-end;
      gap: 0.5rem;
      height: fit-content;
    }
  }

  &__filterButton {
    margin: 0 0.5rem 0.5rem 0;
  }

  &__description {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__scale {
    margin-top: 10.8rem;
    margin-bottom: 10.8rem;
  }

  &__loading {
    height: 3rem;
  }

  &__error {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &--widget {
    padding: 0;
    margin-bottom: 0;
    height: 219px;

    .WorkplaceCultureOverview__loading,
    .WorkplaceCultureOverview__error {
      height: 100%;
      margin-top: 2rem;
    }
    p {
      font-weight: 300;
    }

    .WorkplaceCultureOverview__scale {
      margin-top: 10.8rem;
      margin-bottom: 0;
    }
  }

  &--widget.WorkplaceCultureOverview--noData {
    height: 250px;
  }
}
