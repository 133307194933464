@import "../../styles/theme.scss";

.QuestionBreakdown {
  &__title {
    text-transform: uppercase;
    display: flex;

    align-items: center;
    justify-content: space-between;
  }

  &__filters {
    padding-top: 2rem;

    &__button {
      margin: 0 0.5rem 0.5rem 0;
    }
  }
  &__noAnswer {
    margin-top: 2rem;
  }
  &__noSelectedFilters {
    .NoDataState__text__info {
      max-width: 100%;
    }
  }
}
